/** @jsxImportSource theme-ui */
import React, { useEffect, useRef } from "react";
import gsap from "gsap";

// functions
import LocomotiveScroll from "locomotive-scroll";

// functional components
import SplitText from "../functional-components/split-text";

// components
import Seo from "../utils/seo";
import TransButton from "../components/trans-button";
import ContactLink from "../micro-components/contact-link";

// style
import "../style/contatti.scss";

const Contatti = () => {
  const contatti = useRef(null);
  const mainTl = useRef(null);

  useEffect(() => {
    // init Locomotive Sroll and ScrollTrigger
    const scroll = new LocomotiveScroll({
      el: document.querySelector(".scroll-section"),
      smooth: true,
      multiplier: 0.75,
      firefoxMultiplier: 45,
      lerp: 0.1,
      smartphone: {
        breakpoint: 0,
        smooth: false,
      },
      tablet: {
        breakpoint: 0,
        smooth: false,
      },
    });

    function callback(mutationList) {
      mutationList.forEach((mutation) => {
        switch (mutation.type) {
          case "attributes":
            switch (mutation.attributeName) {
              case "menu-status":
                const state = document
                  .querySelector("[menu-status]")
                  .getAttribute("menu-status");
                if (state === "close") {
                  scroll.start();
                }
                if (state === "open") {
                  scroll.stop();
                }
                break;
              case "main-visible-status":
                const visible = document
                  .querySelector("[main-visible-status]")
                  .getAttribute("main-visible-status");
                if (visible === "visible") mainTl.current.play();

                break;
              default:
                break;
            }
            break;
          default:
            break;
        }
      });
    }

    // Obsever section
    const targetNode1 = document.querySelector("[menu-status]"),
      targetNode2 = document.querySelector("[main-visible-status]");
    // observe menu for changes
    const observer1 = new MutationObserver(callback),
      observer2 = new MutationObserver(callback);
    // Start observing the target node for configured mutations
    observer1.observe(targetNode1, {
      attributes: true,
      attributeFilter: ["menu-status"],
      childList: false,
      subtree: false,
    });
    observer2.observe(targetNode2, {
      attributes: true,
      attributeFilter: ["main-visible-status"],
      childList: false,
      subtree: false,
    });

    // // PUT THERE ALL THE TIMELINES // //
    // define main timeline
    const q = gsap.utils.selector(contatti);
    mainTl.current = gsap.timeline({ paused: true });
    mainTl.current
      .fromTo(
        q("h1 span span"),
        {
          yPercent: 100,
        },
        {
          yPercent: 0,
          stagger: {
            amount: 0.5,
          },
          duration: 1.5,
          ease: "expo.out",
        }
      )
      .fromTo(
        q(".more-important span"),
        {
          yPercent: 250,
        },
        {
          yPercent: 0,
          stagger: {
            amount: 0.25,
          },
          duration: 1,
          ease: "expo.out",
        },
        0.25
      )
      .fromTo(
        q(".contact-container li > *"),
        {
          yPercent: 200,
        },
        {
          yPercent: 0,
          stagger: {
            amount: 0.5,
          },
          duration: 1,
          ease: "expo.out",
        },
        0.5
      )
      .fromTo(
        q(".mid-line"),
        {
          scaleX: 0,
        },
        {
          scaleX: 1,
          duration: 1,
          ease: "power3.inOut",
        },
        0.75
      )
      .fromTo(
        q(".more-container > a"),
        {
          yPercent: 100,
          opacity: 0,
        },
        {
          yPercent: 0,
          opacity: 1,
          ease: "expo.out",
        },
        0.75
      )
      .fromTo(
        [
          q(".more-container p:not(p.more-important) > span"),
          q(".more-container a span:first-child"),
        ],
        {
          yPercent: 200,
        },
        {
          yPercent: 0,
          stagger: {
            amount: 0.5,
          },
          duration: 1,
          ease: "expo.out",
        },
        0.75
      );

    // // DON'T TOUCCHE GNIENTE DOPO QUA  // //
    setTimeout(() => {
      scroll.update();
    }, 10);

    return () => {
      // stop observing
      observer1.disconnect();
      observer2.disconnect();
      // destroy Locomotive Scroll instace on page change
      scroll.destroy();
    };
  }, []);

  return (
    <>
      <Seo
        title="Contatti"
        description="Vuoi parlarmi del tuo progetto? 331 8912895 - contact@giordanobetti.com"
      />
      <section ref={contatti} className="contatti-container">
        <div className="main-contatti-container">
          <h1 sx={{ color: "primary" }}>
            <SplitText text="Hai un nuovo progetto in mente?" />
            <span sx={{ bg: "primary" }} className="mid-line"></span>
          </h1>
          <ul className="contact-container">
            <li>
              <span>Scrivimi una mail a</span>
            </li>
            <li>
              <ContactLink
                to="mailto: contact@giordanobetti.com"
                title="Invia una mail a Giordano Betti"
              >
                contact@giordanobetti.com
              </ContactLink>
            </li>
            <li>
              <span>Chiamami al</span>
            </li>
            <li>
              <ContactLink
                to="tel: +39 331 891 2895"
                title="Chiama Giordano Betti"
              >
                +39 331 891 2895
              </ContactLink>
            </li>
            <li>
              <span>Inviami un messaggio su</span>
            </li>
            <li>
              <ContactLink
                to="https://wa.me/393318912895?text=Salve%2C+ho+un+nuovo+progetto+in+mente+che+vorrei+realizzare%21"
                title="Iniva un messaggio WhatsApp a Giordano Betti"
                external={true}
              >
                WhatsApp
              </ContactLink>
            </li>
          </ul>
          <div className="more-container">
            <p className="more-important">
              <span>
                Non esitare a contattarmi per avere <em>più informazioni</em> o{" "}
                <em>per collaborare</em>!
              </span>
              <span>
                Realizziamo <em sx={{ color: "primary" }}>il tuo progetto</em>{" "}
                insieme.
              </span>
            </p>
            <p>
              <span>Se non sei ancora convinto, prova a controllare</span>
            </p>
            <TransButton to="/portfolio">Alcuni dei miei lavori</TransButton>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contatti;
